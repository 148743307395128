class Banner {
  constructor(settings) {
    this.dismissable = settings.dismissable;
    this.moreInformationLink = settings.moreInformationLink;
    this.type = settings.type;
    this.overlay = settings.overlay;
    this.title = settings.title;
    this.text = settings.text;
    this.baseId = 'banner432'; // With number so it is more likely to be unique
    this.uniqueId = settings.uniqueId ? settings.uniqueId : 'banner';

    this.initialize();
  }

  /**
   * Initialize banner
   */
  initialize() {
    this.buildBaseHtml();
    this.addType();

    if (this.dismissable) {
      this.addDismissableButton();
    }

    if (this.moreInformationLink !== '' && this.moreInformationLink !== null && this.moreInformationLink !== undefined) {
      this.addMoreInformationLink();
    }

    if (this.overlay) {
      this.addOverlay();
    }

    if (this.title !== '' && this.title !== null && this.title !== undefined) {
      this.setTitle();
    }

    if (this.text !== '' && this.text !== null && this.text !== undefined) {
      this.setText();
    }

    if (localStorage.getItem('seen-' + this.uniqueId) === 'yes') {
      this.close();
    }
  }

  /**
   * Build base html without options
   */
  buildBaseHtml() {
    let html = `<div id="banner432">
                        <div class="banner432content">
                            <div>
                                <div class="banner432icon"></div>
                            </div>
                            <div>
                                <h6 id="banner432title"></h6>
                                <p id="banner432text"></p>
                            </div>
                        </div>
                        <div id="banner432tools">
                        </div>
                    </div>`;

    // Add the base HTML to the body
    document.body.insertAdjacentHTML('afterbegin', html);
  }

  /**
   * Adds dismissable button
   */
  addDismissableButton() {
    let tools = document.getElementById('banner432tools');
    let thisObject = this;

    let dismissableButton = document.createElement('div');
    dismissableButton.classList.add('banner432close');
    dismissableButton.onclick = function(e){
      thisObject.close();
    }

    tools.appendChild(dismissableButton);
  }

  /**
   * Closes the banner
   */
  close() {
    let banner = document.getElementById(this.baseId);
    banner.parentNode.removeChild(banner);
    localStorage.setItem('seen-' + this.uniqueId, "yes");
  }

  /**
   * Adds the type class (error, warning or info)
   */
  addType() {
    let banner = document.getElementById(this.baseId);

    switch (this.type) {
      case 'error':
        banner.classList.add('banner432--error');
        break;
      case 'warning':
        banner.classList.add('banner432--warning');
        break;
      default:
        banner.classList.add('banner432--info');
    }
  }

  /**
   * Adds the more information link
   */
  addMoreInformationLink() {
    let tools = document.getElementById('banner432tools');

    let informationLink = document.createElement('a');
    informationLink.innerHTML = 'Meer informatie';
    informationLink.setAttribute('href', this.moreInformationLink);
    informationLink.setAttribute('target', "blank");

    tools.insertBefore(informationLink, tools.childNodes[0]);
  }

  /**
   * Adds the overlay class
   */
  addOverlay() {
    let banner = document.getElementById(this.baseId);

    banner.classList.add('banner432--overlay');
  }

  /**
   * Sets the title
   */
  setTitle() {
    document.getElementById('banner432title').innerText = this.title;
  }

  /**
   * Sets the text
   */
  setText() {
    document.getElementById('banner432text').innerHTML = this.text;
  }

}

export default Banner;
