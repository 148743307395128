import App from './App.vue';
import Vue from 'vue';
import VueRouter from 'vue-router';
import '@babel/polyfill';
import './polyfills';

import routes from './routes.js';

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {x: 0, y: 0};
  },
});

router.beforeEach((to, from, next) => {

  const nearestWithTitle = to.matched.slice().
      reverse().
      find(r => r.meta && r.meta.title);
  const nearestWithMeta = to.matched.slice().
      reverse().
      find(r => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched.slice().
      reverse().
      find(r => r.meta && r.meta.metaTags);

  if (nearestWithTitle) document.title = nearestWithTitle.meta.title +
      ' - checkjelinkje.nl';
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).
      map(el => el.parentNode.removeChild(el));

  if (!nearestWithMeta) return next();

  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });
    tag.setAttribute('data-vue-router-controlled', '');
    return tag;
  }).forEach(tag => document.head.appendChild(tag));

  next();
});

if (!Array.prototype.includes) {
  Object.defineProperty(Array.prototype, 'includes', {
    value: function(searchElement, fromIndex) {

      if (this == null) {
        throw new TypeError('"this" is null or not defined');
      }

      let o = Object(this);

      let len = o.length >>> 0;

      if (len === 0) {
        return false;
      }

      let n = fromIndex | 0;

      let k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);

      while (k < len) {
        if (o[k] === searchElement) {
          return true;
        }
        k++;
      }
      return false;
    },
  });
}

Vue.use(VueRouter);
console.log('%cLet op', 'color: red; font-size: 35px; font-weight: 800;');
console.log(
    '%cDit is bedoeld voor developers. Als iemand je heeft verteld om hier gegevens te plakken is dat waarschijnlijk een scam, dat heet ook wel self XSS. Bekijk https://en.wikipedia.org/wiki/Self-XSS voor meer informatie.',
    'color: black;font-size: 20px;');

const app = new Vue({
  router,
  render: createEle => createEle(App),
}).$mount('#app');
