<template>

  <nav class="navbar navbar-dark navbar-expand-lg">
    <div class="container">

      <router-link class="navbar__brand" to="/">
        <img src="/img/navbar-logo.svg" height="62" alt="">
      </router-link>

      <div class="vertical--line d-none d-lg-block"></div>

      <button class="navbar-toggler" type="button" data-toggle="collapse"
              data-target="#navbarSupportedContent"
              style="border-color:transparent" aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation">

        <span class="navbar-toggler-icon color--white"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">

        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link class="navbar__link" to="/over">Over Checkjelinkje</router-link>
          </li>
          <li class="nav-item">
            <router-link class="navbar__link" to="/betaalverzoekcheck">Betaalverzoek Check</router-link>
          </li>
          <li class="nav-item">
            <router-link class="navbar__link" to="/training">Awareness</router-link>
          </li>
        </ul>

        <ul class="navbar-nav ml-auto">

          <li class="nav-item d-block d-md-none" v-if="$route.name !== 'home'">
            <router-link class="navbar__link" to="/">Een linkje checken <i
                class="far fa-arrow-right d-none d-md-inline"></i></router-link>
          </li>

          <li class="nav-item d-none d-lg-block" v-if="$route.name !== 'home'">
            <router-link class="navbar__link button--transparent" role="button"
                         to="/">Een linkje checken <i class="far fa-arrow-right d-none d-md-inline"></i>
            </router-link>
          </li>

          <li class="nav-item d-none d-lg-block"
              v-if="desktopSupportsPlugin">
            <div v-if="browser === 'chrome'">
              <a class="navbar__link-blue button--white" role="button"
                 href="https://chrome.google.com/webstore/detail/checkjelinkjenl-voor-je-b/emhmcmaagaihdokaiccmmidnfndeilil"
                 target="_blank">
                Download extensie<i class="fab fa-chrome padding__left--10"></i></a>
            </div>
            <div v-if="browser === 'firefox'">
              <a class="navbar__link-blue button--white" role="button"
                 href="https://addons.mozilla.org/nl/firefox/addon/checkjelinkje-nl-in-je-browser/" target="_blank">
                Download extensie<i class="fab fa-firefox padding__left--10"></i></a>
            </div>

          </li>

        </ul>
      </div>
    </div>
  </nav>
</template>

<script>

import detect from 'detect-browser';

export default {
  name: 'Navigation',
  data: function() {
    return {
      browser: '',
      os: '',
    };
  },
  mounted: function() {
    let browser = detect.detect();
    this.browser = browser.name;
    this.os = browser.os;
  },
  methods: {
    desktopSupportsPlugin: function() {
      return ['Mac OS', 'Windows XP', 'Windows 7', 'Windows 8.1', 'Windows 10', 'Linux'].includes(os) &&
          ['chrome', 'firefox'].includes(browser);
    },
  },
};

</script>

<style scoped>
.navbar__link, .navbar__link-blue {
  text-decoration: none;
}

.vertical--line {
  border-left: 2px solid white;
  height: 50px;
  margin: 0 10px 0 20px;
}

.padding__left--10 {
  padding-left: 10px;
}

.color--white {
  color: white;
}

</style>
