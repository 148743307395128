import Home from './pages/Home';

const routes = [
  {
    path: '/',
    component: Home,
    name: 'home',
    meta: {
      title: 'Checkjelinkje',
      metaTags: [
        {
          name: 'description',
          content: 'Met Checkjelinkje controleer je heel eenvoudig of een link (waarschijnlijk) veilig is.',
        },
      ],
    },
  },

  {
    path: '/over',
    component: () => import(/* webpackChunkName: "about" */ './pages/About.vue'),
    meta: {
      title: 'Over Checkjelinkje',
      metaTags: [
        {
          name: 'description',
          content: 'Met Checkjelinkje controleer je of een link (waarschijnlijk) veilig is. Op onze over Checkjelinkje-pagina lees je er alles over.',
        },
      ],
    },
  },

  {
    path: '/juridisch',
    component: () => import(/* webpackChunkName: "legal" */ './pages/Juridisch.vue'),
    meta: {
      title: 'Privacy en voorwaarden',
      metaTags: [
        {
          name: 'description',
          content: 'De privacyverklaring en disclaimer van Checkjelinkje.',
        },
      ],
    },
  },

  {
    path: '/privacy',
    component: () => import(/* webpackChunkName: "privacy" */ './pages/Privacy.vue'),
    meta: {
      title: 'Privacyverklaring',
      metaTags: [
        {
          name: 'description',
          content: 'De privacyverklaring van Checkjelinkje.',
        },
      ],
    },
  },

  {
    path: '/check/*',
    component: () => import(/* webpackChunkName: "checker" */ './pages/Checker.vue'),
    meta: {
      title: 'Checker',
      metaTags: [
        {
          name: 'description',
          content: 'Het resultaat van de check, hier kan je bekijken hoe veilig een link is of dat je de link beter links kunt laten liggen.',
        },
      ],
    },
  },

  {
    path: '/browser',
    component: () => import(/* webpackChunkName: "browser" */ './pages/Browser.vue'),
    meta: {
      title: 'Browserextensie',
      metaTags: [
        {
          name: 'description',
          content: 'Blijf continu beschermd tegen phishingaanvallen met de browserextensie van checkjelinkje.nl',
        },
      ],
    },
  },

  {
    path: '/staysafe',
    component: () => import(/* webpackChunkName: "staysafe" */ './pages/StaySafe.vue'),
    meta: {
      title: 'Stay safe',
      metaTags: [
        {
          name: 'description',
          content: 'We leven in een bijzondere tijd. Phishers maken daar helaas dankbaar gebruik van. Daarom willen we je met een aantal eenvoudige tips helpen je te weren tegen phishers.',
        },
      ],
    },
  },

  {
    path: '/betaalverzoekcheck',
    component: () => import(/* webpackChunkName: "payment-request-check" */ './pages/BetaalverzoekCheck.vue'),
    meta: {
      title: 'Betaalverzoek Check',
      metaTags: [
        {
          name: 'description',
          content: 'Betaalverzoekje ontvangen? Tegenwoordig de normaalste zaak van de wereld. Maar, voordat je ‘m betaalt, check dan even of alles in orde is.',
        },
      ],
    },
  },

  {
    path: '/training',
    component: () => import(/* webpackChunkName: "phishing-awareness-training" */ './pages/PhishingAwarenessTraining.vue'),
    meta: {
      title: 'Phishing Awareness Training',
      metaTags: [
        {
          name: 'description',
          content: 'Betaalverzoekje ontvangen? Tegenwoordig de normaalste zaak van de wereld. Maar, voordat je ‘m betaalt, check dan even of alles in orde is.',
        },
      ],
    },
  },

];

export default routes;
