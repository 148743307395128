<template>
    <div class="jumbotron jumbotron-fluid title__primary--home">

        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <h1 class="display-3 title-center">Check je <u>linkje</u></h1>
                    <div class="space--10"></div>
                    <p class="lead--centered">
                        <!--                        Omdat je sommige links nu eenmaal beter links kunt laten liggen.-->

                        Met Checkjelinkje<sup><small>®</small></sup> controleer je heel eenvoudig of een link veilig is
                    </p>
                    <div class="space--30"></div>
                </div>
            </div>

            <div class="form-group form__box" v-bind:class="{ shake: error, animated: error }">
                <div class="input-group input-group-lg form__flex">

                    <input data-hj-suppress placeholder="Plak hier je linkje" v-model="url" type="url"
                           class="form__input"
                           aria-label="URL"
                           aria-describedby="inputGroup-sizing-lg" v-on:keyup.enter="check()" autofocus>

                    <div class="btn__check">
                        <button class="btn btn__check--circle" v-on:click="check()" type="button"
                                id="button-addon2"><i class="far fa-arrow-right btn__icon"></i>
                        </button>
                    </div>
                </div>
            </div>

            <div class="space--20"></div>

            <div class="row" v-if="error">
                <div class="col-md-4">
                    <p class="form__error--text" v-bind:class="{ 'opacity--1': error }">Voer een geldige link in.</p>
                </div>
            </div>

<!--            <div class="space space&#45;&#45;30"></div>-->
<!--            <div class="row text&#45;&#45;white">-->
<!--                <div class="col-md-4 text-center">-->
<!--                    <h6><i class="far fa-check"></i> Check waar een verkorte URL naartoe gaat</h6>-->
<!--                </div>-->
<!--                <div class="col-md-4 text-center">-->
<!--                    <h6><i class="far fa-check"></i> Check of een link malware/phishing bevat</h6>-->
<!--                </div>-->
<!--                <div class="col-md-4 text-center">-->
<!--                    <h6><i class="far fa-check"></i> Check of een link naar een banksite gaat</h6>-->
<!--                </div>-->
<!--            </div>-->
            <div class="space space--40"></div>

            <div class="row">
                <div class="col-md-12">
                    <p class="lead text-center d-none d-md-block">
                        <span class="badge badge-secondary">Nieuw</span>
                        <router-link class="text--white" to="/training">
                          Maak je medewerkers weerbaarder tegen phishingaanvallen <i class="far fa-arrow-right"></i>
                        </router-link>
                    </p>
                    <p class="lead text-center d-block d-md-none">
                        <span class="badge badge-secondary">Nieuw</span><br><span class="space space--10"></span>
                      <router-link class="text--white" to="/training">
                        Maak je medewerkers weerbaarder tegen phishingaanvallen <i class="far fa-arrow-right"></i>
                      </router-link>
                    </p>
                </div>
            </div>
        </div>
        <Modal id="cjl-biz-modal">
            <template slot="title">
                <i class="far fa-hourglass"></i> Nog even geduld...
            </template>
            <template slot="body">
                <p>
                    Bedankt voor je interesse! We zijn nog bezig met ons aanbod voor bedrijven, je kunt het nu helaas
                    dus nog niet gebruiken.
                </p>
                <p>
                    Omdat je erop hebt geklikt, weten we wel dat er interesse in is. Kom je binnenkort nog eens terug?
                    Dan weet je als eerste wanneer het beschikbaar is!
                </p>
            </template>
        </Modal>
    </div>

</template>

<style scoped>
    .badge {
        color: #316fe6;
        background-color: #fff;
        font-size: 100%;
    }

    .panel a {
        color: #fff;
    }

    .jumbotron {
        margin-bottom: 1rem;
    }

    .text--white {
        color: white;
    }

    .text-center {
        text-align: center;
    }

    .panel a {
        color: #fff;
    }

</style>

<script>
  import detect from "detect-browser";
  import Modal from "../components/Modal";
  import MicroModal from "micromodal";
  import app_config from "../config";
  import uuid from "uuid";

  export default {
    name: "Home",
    components: { Modal },
    data: function () {
      return {
        url: "",
        error: false,
        browser: "",
        os: "",
      };
    },
    mounted: function () {
      let browser = detect.detect();
      this.browser = browser.name;
      this.os = browser.os;
    },
    methods: {
      /**
       * Track Checkjelinkje Business interest.
       *
       * @returns {Promise<void>}
       */
      checkjelinkjeBusinessCta: async function () {
        MicroModal.show("cjl-biz-modal");

        if (localStorage.getItem("cjl-biz-cta-201909-a")) {
          return;
        }

        let body = {
          "event": {
            "name": "cjl-biz-cta-201909-a",
          }
        };

        await fetch(`https://${app_config.su_api_host}/v2/events/track`, {
          method: "POST",
          body: JSON.stringify(body),
          headers: new Headers({
            "content-type": "application/json",
            "x-su-client-version": app_config.client_version,
            "x-su-client-platform": "checkjelinkje-nl",
            "x-su-request-uuid": uuid.v4(),
          })
        });

        localStorage.setItem("cjl-biz-cta-201909-a", "net");
      },
      check: function () {
        if (this.url.includes(".")) {

          this.$router.push("/check/" + encodeURIComponent(this.url));

        } else {
          this.error = true;

          let self = this;
          setTimeout(function () {
            self.error = false;
          }, 2000);
        }
      }
    }
  };
</script>
