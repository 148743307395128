<template>
    <div>
        <div v-bind:class="{disabled: !is_available}">
            <Navigation/>
            <router-view></router-view>
            <Footer/>
        </div>
        <Modal id="checkjelinkjeBusiness">
            <template slot="title">
                <i class="fas fa-question-circle"></i> Mogen we je wat vragen?
            </template>
            <template slot="body">
                <p>
                    Als je op zoek bent naar <strong>manieren om je bedrijf, je medewerkers en je klanten te beschermen tegen phishing</strong>, dan
                    hebben we wat vragen voor je. Het zijn er maar
                    een paar, kost je maximaal <strong>een minuutje</strong>.
                </p>
                <p>
                    Als je de vragen niet wilt beantwoorden kun je deze pop-up sluiten. We zullen het je dan niet meer
                    vragen.
                </p>
                <p>
                    <small>
                        <i class="far fa-shield-check text-primary"></i> Je antwoorden op onze vragenlijst worden niet
                        gekoppeld aan je geschiedenis op Checkjelinkje.
                    </small>
                </p>
            </template>
            <template slot="footer">
                &nbsp;&nbsp;
                <a href="https://freave.co/checkjelinkjezakelijk-mt" v-on:click="mmClosed" target="_blank"
                   class="btn btn-primary btn-sm">
                    Vul ik even in <i class="far fa-arrow-right"></i>
                </a>
            </template>
        </Modal>
    </div>
</template>
<script>

  import Navigation from './components/Navigation.vue';
  import Footer from './components/Footer.vue';
  import Banner from './Banner.js';
  import Modal from './components/Modal';
  import MicroModal from 'micromodal';

  export default {
    name: 'App',
    components: {Modal, Navigation, Footer},
    data: () => {
      return {
        is_available: true,
        unavailable: {
          reason: '',
        },
      };
    },
    mounted: async function() {
      let now = new Date();
      this.checkAvailability();

      if (now.getHours() >= 9 && now.getHours() < 17) {
        if (localStorage.getItem('mm_closed') !== 'yes') {
          //MicroModal.show('checkjelinkjeBusiness');
        }
      }
    },
    methods: {
      mmClosed: () => {
        localStorage.setItem('mm_closed', 'yes');
        MicroModal.close('checkjelinkjeBusiness');
      },
      displayBelgiumRegionMessage: function() {
        new Banner({
          dismissable: true,
          uniqueId: 'belgium_region_message',
          type: 'info',
          title: '🇧🇪 Leuk dat je er bent',
          text: 'Het lijkt erop dat je onze site bezoekt vanuit België. We willen je er op wijzen dat checkjelinkje.nl het beste werkt voor inwoners van Nederland. Zodra Checkjelinkje beschikbaar wordt in België laten we het je weten.',
          overlay: false,
        });
      },
      displayError: function() {
        new Banner({
          dismissable: false,
          type: 'error', // error, info or warning are your options here
          title: 'Oeps, storing.',
          text: this.unavailable.reason,
          overlay: false,
        });
      },
      checkAvailability: async function() {
        let availabilityResponse;

        try {
          availabilityResponse = await fetch('https://availability.apis.checkyourlink.com/v1/get-status');
        } catch (exception) {
          return false;
        }

        if (availabilityResponse.status !== 200) {
          return;
        }

        let availability = await availabilityResponse.json();
        this.is_available = availability.data.is_available;

        if (availability.data.client.region === 'BE' && this.is_available) {
          this.displayBelgiumRegionMessage();
        }

        if (!this.is_available) {
          this.unavailable.reason = availability.data.unavailable.reason;
          this.displayError();
        }
      },
    },
  };
</script>
