<template>
  <footer class="footer" style="background-color:#1856cd">
    <div class="container footer__content">

      <div class="row">

        <div class="col-md-6 footer__text">
          <router-link to="/"><img src="/img/check-white.svg" alt="Checkjelinkje vinkje" height="17"></router-link>
        </div>

        <div class="col-md-3 ml-md-auto d-none d-md-block">
          <router-link class="footer__link float--right text-white" to="/juridisch">Privacy en voorwaarden
          </router-link>
        </div>

      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
};
</script>
