<template>
    <div class="modal micromodal-slide" aria-hidden="true">
        <div class="modal__overlay" tabindex="-1" data-micromodal-close>
            <div class="modal__container" role="dialog" aria-modal="true" aria-labelledby="modal-title">

                <header class="modal__header">
                    <h2 class="modal__title" id="modal-title">
                        <slot name="title"></slot>
                    </h2>
                    <button class="modal__close" v-on:click="mmClosed" aria-label="Sluit deze pop-up" data-micromodal-close></button>
                </header>

                <main class="modal__content" id="reminder-content">
                    <p>
                        <slot name="body"></slot>
                    </p>
                </main>

                <footer class="modal__footer">
                    <button class="modal__btn" data-micromodal-close v-on:click="mmClosed"
                            aria-label="Sluit deze pop-up">Sluiten
                    </button>
                    <slot name="footer"></slot>
                </footer>

            </div>
        </div>
    </div>
</template>
<script>
  import MicroModal from 'micromodal';

  export default {
    name: 'Modal',
    mounted: function() {
      MicroModal.init();
    },
    methods: {
      mmClosed: () => {
        localStorage.setItem('mm_closed', 'yes');
      },
    },
  };
</script>
